.footer-container {
  width: 100% !important;
  /* position: absolute;
  bottom: 0; */
  /* margin: 0 !important; */
  height: 150px;
  background-color: #eeeeee;
  text-align: center;
}

.footer-wrapper {
  padding-top: 20px;
}

.footer-text {
  color: black;

  font-weight: 100;
  font-size: small;
  text-align: center;
}

.footer-links {
  text-align: right;
}
.footer-link {
  text-decoration: none;
  color: darkgray;
  font-size: small;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.footer-links-title {
  margin: 0;
}

.footer-company-name {
  display: inline;
}

.footer-company-icon {
  display: inline;
  width: 20px;
  margin-right: 5px;
}

.footer-icons {
  text-align: center;
  padding-top: 2vh;
  font-size: x-large;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.footer-icon {
  padding-left: 10px;
  padding-right: 10px;
  color: black;

  padding-bottom: 10px;
}

.footer-icon:hover {
  color: lightblue;
}
