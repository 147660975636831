#hero-contact {
  background: url("https://ryan-photo-hosting-bucket.s3-us-west-1.amazonaws.com/give-sounds/givesounds-1-fix.jpg");
  background-size: cover;
  background-position: center center;
  /* background-attachment: fixed; */
  position: relative;
  top: -20;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.contact-container {
  position: relative;
  min-height: 100vh;
  text-align: center;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.contact-header {
  color: rgb(63, 63, 63);
  /* font-family: "Khula", sans-serif; */
  /* font-size: 1.9rem; */
  font-family: "Montserrat", sans-serif;
  font-size: 1.9rem;
  text-align: center;
  margin-top: 2vh;
}

.contact-info {
  margin-top: 6vh;
}

.contact-subheader {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: lighter;
  font-size: 1.6rem;
}

.lottie-container {
  height: 40px;
  width: 40px;
  position: relative;
  bottom: 53px;
  left: 75%;
}

.content-wrapper {
  padding-top: 1vh;
  padding-bottom: 5vh;
}

.alert {
  margin-top: 5vh;
  height: 50px;
  text-align: center;
  color: white;
}

.fail-alert {
  background-color: #e53935;
}
.sending-alert {
  background-color: #42a5f5;
}

.success-alert {
  background-color: #43a047;
}

.success-alert-text {
  font-size: small;
  text-align: left;
}

.text-logo {
  margin-left: 5%;
  margin-right: 5%;
  position: fixed;
  top: 10%;
  max-height: 25vh;
  max-width: 100%;
  z-index: 2;
}

.center-image {
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 20px;
  width: 30%;
}
.custom-header {
  color: rgb(63, 63, 63);
  font-family: "Montserrat", sans-serif;
  font-size: 1.9rem;
  text-align: center;
}

.info {
  color: black;
  margin: 0px 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.contact-img {
  top: 40vh;
}

/* START BUTTON */
/* 
.contact-button {
  display: flex;
  margin: auto;
  margin-top: 5vh;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  border-radius: 25px;
  padding: 15px;
  color: white;
  background: linear-gradient(
    120deg,
    rgb(15, 134, 231) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  border: none;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
} */

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .form-group {
    align-items: center;
  }
  .form-container {
    width: 500px;
  }

  .sending-alert-text {
    left: 40%;
  }

  .alert {
    padding: 10px;
  }
}
