body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #fafafa !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: local("Gilroy-ExtraBold"),
    url(../src/resources/Gilroy-ExtraBold.otf) format("otf");
}

.act-btn:hover {
  color: white;
  text-decoration: none;
  /* background-color: #2EE59D; */
  /* box-shadow: 0px 15px 20px rgba(0, 0, 0, 0); */
  /* box-shadow: 0 0.5em 0.5em -0.4em var(--hover); */
  /* box-shadow: 1px 2px 2px #888888; */
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);

  color: #fff;
  transform: translateY(-4px);
}

.act-btn-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.act-btn {
  text-align: center;
  text-decoration: none !important;

  width: 50%;

  display: flex;
  margin: auto;
  margin-top: 5vh;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 15px;
  color: white;
  background: rgb(0, 74, 134);
  background: linear-gradient(
    120deg,
    rgb(72, 145, 204) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  justify-content: center;

  text-decoration: none;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none;
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .home-image {
    width: 50%;
  }

  .act-btn {
    width: 50%;
  }
}
