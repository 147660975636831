.work-container {
  position: relative;
  min-height: 100vh;
  text-align: center;
}

.content-wrapper {
  padding-top: 50px;
  padding-bottom: 5vh;
}

.text-logo {
  margin-left: 5%;
  margin-right: 5%;
  position: fixed;
  top: 10%;
  /* max-height: 90vh; */
  max-height: 25vh;
  max-width: 100%;
  z-index: 2;
}

.work-job-header {
  color: rgb(63, 63, 63);
  font-family: "Montserrat", sans-serif;
}

.center-image {
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 20px;
  width: 30%;
}
.custom-header {
  color: rgb(63, 63, 63);
  /* font-family: "Khula", sans-serif; */
  /* font-size: 1.9rem; */
  font-family: "Montserrat", sans-serif;
  font-size: 1.9rem;
  text-align: center;
  margin-top: 2vh;
}

.info {
  color: black;
  margin: 0px 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.work-img {
  /* top: 40vh; */
  /* width: 100%; */
  /* height: 20vh;
  width: auto; */
  max-width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 2vh;
}

.work-description {
  padding-right: 5%;
  padding-left: 5%;
  color: rgb(63, 63, 63);
  font-family: "Montserrat", sans-serif;
  font-size: medium;
  padding-bottom: 4vh;
}

/* START BUTTON */

.contact-button {
  display: flex;
  margin: auto;
  margin-top: 5vh;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  border-radius: 25px;
  padding: 15px;
  color: white;
  background: linear-gradient(
    120deg,
    rgb(15, 134, 231) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  border: none;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.work-video {
  max-width: 100%;
}

@media only screen and (min-width: 600px) {
  .center-image {
    width: 20%;
  }

  .work-video {
    margin: auto;
  }
}
