#hero-harmonic {
  background: url("https://ryan-photo-hosting-bucket.s3-us-west-1.amazonaws.com/ev-7.jpg");
  background-size: cover;
  background-position: center center;
  /* background-attachment: fixed; */
  position: relative;
  top: -20;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.harmonic-text {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  margin-bottom: 4vh;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.offerings-container {
  position: relative;
  min-height: 100vh;
  text-align: center;
}

.content-wrapper {
  padding-top: 50px;
  padding-bottom: 5vh;
}

.offerings-title {
  color: rgb(63, 63, 63);
  /* font-family: "Khula", sans-serif; */
  /* font-size: 1.9rem; */
  font-family: "Montserrat", sans-serif;
  font-size: 1.9rem;
  text-align: center;
  margin-top: 2vh;

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.offerings-header {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  margin-top: 3vh;
  font-size: 1.6rem;

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@media only screen and (min-width: 600px) {
  .offerings-header {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: bold;
    margin-top: 3vh;
  }
  .offerings-list {
    text-align: left;
    padding: 0px 70px;
  }
  .harmonic-text {
    font-size: 1.4rem;
  }
}
