.nav-container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;
  background-color: whitesmoke !important;
}

.custom-navbar {
  background-color: #fafafa !important;
  text-align: center;
}

.nav-logo {
  position: relative;
  width: 35px;
  margin-right: 25px;
  display: inline-block;
}

.nav-company-name {
  color: rgb(63, 63, 63);
  font-size: x-large;
  font-family: "Montserrat", sans-serif;

  /* position:  */
  display: inline-block;
  padding-left: 1vw;
  position: relative;
  top: 5px;
}

.nav-company-name:hover {
  color: #9e9e9e;
}

.navbar-light {
  box-shadow: none !important;
}

.nav-link-logo {
  padding: none !important;
  margin-top: 0 !important;
  text-decoration: none !important;
  align-items: center;
}

@media only screen and (min-width: 600px) {
  .nav-logo {
    width: 50px;
  }
  .nav-company-name {
    padding-left: 0;
  }
}

.no-bs-border,
.no-bs-border:focus,
.no-bs-border:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: medium !important;
}

.dropdown-item {
  color: #e1e1e1 !important;
}

.dropdown-toggle {
  font-size: medium;
}

.dropdown-item:hover {
  background-color: #121212 !important;
}

.navbar-radius {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.navbar-collapse {
  position: relative;
  right: 0;
}

.custom-navbar-brand {
  color: #e1e1e1 !important;
}

.custom-navbar-brand:hover {
  color: #9e9e9e !important;
}

.navbar-nav {
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .nav-link-logo {
    margin-left: 20px;
    margin-right: 20px;
  }
  .nav-logo {
    width: 40px;
  }
}
