.careers-container {
  position: relative;
  min-height: 100vh;
  text-align: center;
  margin-top: 3vh;
}

.content-wrapper {
  padding-top: 50px;
  padding-bottom: 5vh;
}

.careers-image {
  position: relative;
}
.home-header {
  color: rgb(63, 63, 63);
  /* font-family: "Khula", sans-serif; */
  /* font-size: 1.9rem; */
  font-family: "Source Sans Pro", sans-serif;
  font-size: 2.5rem !important;
  text-align: center;
  margin-top: 8vh;
  text-transform: uppercase;
  /* font-weight: bold !important; */
  letter-spacing: 3px;
  /* position: relative; */
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-text-container {
  position: relative;
  margin: 40px 0px;
  font-family: "Calibri", sans-serif;
  padding: 10px;
  width: 100%;
  margin: auto;
  text-align: left;
}

.home-title {
  font-weight: bolder;
  font-size: 1.1rem;
  font-weight: lighter;
  margin: 40px 0px;
  text-align: center !important;
  font-family: "Source Sans Pro", sans-serif;

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.home-body-text {
  position: relative;
  text-align: left;
  /* padding: 0px 60px; */
  font-size: 1.2rem;
}

.home-list {
  position: relative;
  text-align: left;
  padding: 0px 10px;
  font-size: 1.2rem;
  margin: 30px 0px;
}

.home-structures {
  letter-spacing: 1px;
  text-align: center;
  font-size: 1.6rem;
}

/* START PEN */

#hero-home {
  background: url("https://ryan-photo-hosting-bucket.s3-us-west-1.amazonaws.com/give-sounds/give-sounds-home.jpg");
  background-size: cover;
  background-position: center center;
  /* background-attachment: fixed; */
  position: relative;
  top: -20;
}
#hero2 {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/21555/Melissani-cave-natural.jpg);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}
.hero,
.content {
  text-align: center;
  position: relative;
  width: 100%;
}

.inner-header {
  min-height: 400px;
  position: relative;
}

.hero .inner-header {
  background: rgba(88, 88, 88, 0.7)
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAYAAACddGYaAAAAD0lEQVQIW2NkQABjRmQOAAM+AGkQsDBSAAAAAElFTkSuQmCC)
    repeat;
}

.copy {
  position: absolute;
  top: 50%;
  height: 10em;
  margin-top: -5em;
  width: 100%;
}

.hero h1,
.hero p {
  color: #fff;
}

.content h1,
.content p {
  color: #333;
}

.home-video-container {
  /* margin-bottom: 4vh; */
  /* max-width: 80%; */
  /* background-color: rgb(204, 204, 204); */
  /* padding: 20px; */
  /* margin: 0px 140px; */

  margin-bottom: 4vh;
  /* border-radius: 20pt; */
}

.home-video {
  max-width: 90%;
}

/* END PEN */

@media only screen and (min-width: 600px) {
  .home-text-container {
    position: relative;
    margin: 40px 0px;
    font-family: "Source Sans Pro", sans-serif !important;
    padding: 10px 40px;
    width: 80%;
    margin: auto;
    text-align: left;
  }

  .home-title {
    font-weight: bolder;
    font-size: 1.5rem;
    margin: 40px 0px;
    text-align: left;
  }

  .home-body-text {
    position: relative;
    text-align: left;
    font-size: 1.2rem;
  }

  .home-list {
    position: relative;
    text-align: left;
    padding: 0px 50px;
    font-size: 1.2rem;
    margin: 30px 0px;
  }

  .home-structures {
    letter-spacing: 1px;
    font-size: 2.1rem;
  }
  .home-video-container {
    /* margin-bottom: 4vh; */
    /* max-width: 80%; */
    /* background-color: rgb(204, 204, 204); */
    padding: 20px;
    /* margin: 0px 140px; */
    margin-bottom: 4vh;
    /* border-radius: 20pt; */
  }
}
